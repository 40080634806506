import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./graph-ai-body.module.scss";
import ChatHistory from "./chat-history";
import GraphChart from "./graph-chart";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  getAthleteList,
  getCurrentAthlete,
} from "store/slices/shared";
import {
  getAiGraphChatHistory,
  getAiGraphChatQuery,
  getAiGraphComputeData,
  getAiGraphConfiguration,
  updateAiGraphConfig,
  updateMultipleAthleteConfig,
} from "api/ai-graph";
import { countryCodesMapping } from "country-codes-mapping";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import { ReactComponent as DropDown } from "images/dropdown.svg";
import { AthletePopup } from "./athlete-popup/athlete-popup";
import {
  fetchAiGraphId,
  setAiGraphId,
} from "store/slices/aiGraph";
import ChatAiBody from "./graph-ai-chat-body";
import GraphAiChatInput from "./graph-ai-chat-input";
import { AIComputeResponse, Athlete, AthleteType, Axis, ChatBotMessage, Plot, PlotData, YAxis, YAxisData } from "types";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import {
  COLOR_TOOLTIP,
  COLOR_WHITE,
} from "components/charts/colors";
import HorizontalLoader from "./components/horizontal-loader";

type NameValueObject = { [key: string]: any };

const GraphAiBody = () => {
  const dispatch = useDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const athleteList = useSelector(getAthleteList);
  const aiGraphId = useSelector((state: any) => state?.aiGraph?.aiGraphId);

  const [isGraphExpanded, setIsGraphExpanded] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [userMessage, setUserMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredAthlete, setFilteredAthlete] = useState<Athlete[] | any>();
  const [togglePopup, setTogglePopup] = useState(false);
  const [chatBotMessages, setChatBotMessages] = useState<ChatBotMessage[]>([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [graphHistory, setGraphHistory] = useState<[]>([]);
  const [isNewChat, setIsNewChat] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [turnProcess, setTurnProcess] = useState<[]>([]);
  const [activateGraph, setActivateGraph] = useState(false);
  const [graphId, setGraphId] = useState(aiGraphId);
  const [historyId, setHistoryId] = useState("");
  const [computeData, setComputeData] = useState<AIComputeResponse | null>(null);
  const [latestMsgId, setLatestMsgId] = useState("");
  const [onDeleteGraph, setOnDeleteGraph] = useState(false);
  const [chatTitleUpdated, setChatTitleUpdated] = useState(false);
  const [filterAthleteDetails, setFilterAthleteDetails] = useState<AthleteType[] | []>([]);
  const [tempFilterAthleteDetails, setTempFilterAthleteDetails] = useState<AthleteType[] | []>(filterAthleteDetails);
  const [isSubmit, setIsSubmit] = useState(false)

  // Function to convert object to sparse series data
  function convertToSparseSeriesData(
    obj: NameValueObject
  ): { name: string; value: string }[] {
    return Object.entries(obj).map(([key, value]) => ({ name: key, value }));
  }

  const handleExpandClick = () => {
    setIsGraphExpanded(!isGraphExpanded);
  };

  const handleInputChangeMultiline = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setUserMessage(e.target.value);
  };

  function customDateFormatter(value: string): string {
    const date = new Date(value);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }

  // history api call
  useEffect(() => {
    (async () => {
      const res = await getAiGraphChatHistory();
      const data = res?.reverse();
      setGraphHistory(data);
      if (res?.length == 0) {
        dispatch(setAiGraphId(""));
        setGraphId("");
        setHistoryId("");
        setChatBotMessages([
          {
            message: "Hello, How can I help you ?",
            isUser: false,
            isNewMessage: false,
            id: "",
            isDefaultMsg: true,
            turnSubprocesses: [],
          },
        ]);
      }
      setHistoryLoading(false);
    })();
  }, [onDeleteGraph, dispatch]);

  // to call History api when Clicked on new session
  const processHistory = async () => {
    const res = await getAiGraphChatHistory();
    const data = res?.reverse();
    setGraphHistory(data);
    setHistoryLoading(false);
  };

  // initally set GraphId either from redux or from historyId
  useEffect(() => {
    if (aiGraphId) {
      setGraphId(aiGraphId);
    }
    if (historyId) {
      setComputeData(null);
      setGraphData({});
      setActivateGraph(false);
      setGraphId(historyId);
      dispatch(setAiGraphId(historyId));
    }
  }, [aiGraphId, historyId, dispatch]);

  // default chat-bot message
  useEffect(() => {
    setChatBotMessages([
      {
        message: "Hello, How can I help you?",
        isUser: false,
        isNewMessage: false,
        id: "",
        isDefaultMsg: true,
        turnSubprocesses: [],
      },
    ]);
  }, [historyId]);

  // config api call & compute api call on refreshing the page
  useEffect(() => {
    (async () => {
      if (graphId && graphId === aiGraphId) {
        try {
          const configResult = await getAiGraphConfiguration(graphId);

          if (
            configResult &&
            configResult?.conversation_turns?.turns?.length > 0
          ) {
            configResult?.conversation_turns?.turns?.map((turn: any) => {
              const historyMsg = {
                message: turn?.message,
                isUser: turn?.actor === "user",
                isNewMessage: false,
                id: turn?.id,
                isDefaultMsg: false,
                turnSubprocesses: turn?.turn_subprocesses,
              };
              setChatBotMessages((prevMsg) => [...prevMsg, historyMsg]);
            });

            setLatestMsgId(
              configResult?.conversation_turns?.turns?.[
                configResult?.conversation_turns?.turns?.length - 1
              ]?.id
            );
          }
          // to get filtered athlete name from config api
          if (
            configResult?.ai_graph_in_scope?.data_sources_new
              ?.datasource_group_specific
          ) {
            const groupSpecificMetadata = Object.values(
              configResult?.ai_graph_in_scope?.data_sources_new
                ?.datasource_group_specific
            ).flatMap((group: any) => group.map((item: any) => item.metadata));

            setFilterAthleteDetails(groupSpecificMetadata);
            setTempFilterAthleteDetails(groupSpecificMetadata);
          }
          setIsLoading(true);
          const computeDataResponse = await getAiGraphComputeData(graphId);

          // set loading = false, if no computed data is available
          if (computeDataResponse?.ai_graph_in_scope?.ai_generated_graphs === undefined)
            setIsLoading(false);
          // check if computed data is available
          const computedData =
            computeDataResponse?.ai_graph_in_scope?.ai_generated_graphs[0]
              ?.computed_data;
          if (
            computedData?.plots_data?.length > 0 &&
            computedData?.x_axes_data?.length > 0 &&
            computedData?.y_axes_data?.length > 0
          ) {
            setActivateGraph(true);
            setComputeData(computeDataResponse);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Failed to fetch data:", error);
        }
      }
    })();
  }, [graphId, aiGraphId]);

  //ask api call
  useEffect(() => {
    if (graphId && isButtonClicked) {
      setIsButtonClicked(false);
      const handleChunkReceive = (chunk: string) => {
        const lines = chunk.split("\n");

        lines.forEach((line) => {
          if (line.startsWith("data: ")) {
            const jsonData = line.substring(6);

            try {
              const parsedChunk = JSON.parse(jsonData);
              const id = parsedChunk.id;
              setLatestMsgId(id);
              const message = parsedChunk.message || null;
              const turnSubprocesses = parsedChunk.turn_subprocesses;
              const isUser = parsedChunk.actor !== "assistant";
              setIsLoading(!message && true);
              const newMessage = {
                id,
                message,
                isUser,
                turnSubprocesses,
                isNewMessage: true,
                isDefaultMsg: false,
              };
              setTurnProcess(turnSubprocesses);

              setChatBotMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages?.length - 1] = newMessage;
                return updatedMessages;
              });
            } catch (err) {
              setIsLoading(false);
              console.error("Failed to parse JSON:", err);
            }
          }
        });
      };

      (async () => {
        const resp = await getAiGraphChatQuery(
          graphId,
          userMessage,
          handleChunkReceive
        );
        await getAiGraphConfiguration(graphId);
        setIsLoading(true);
        const res = await getAiGraphComputeData(graphId);
        setActivateGraph(true);
        setComputeData(res);
        setIsLoading(false);
        // calling history api to re-render the ai_generated message
        processHistory();
        setChatTitleUpdated(true);
        setUserMessage("");
      })();
    }
  }, [graphId, userMessage, isButtonClicked, chatBotMessages]);

  // to assign new graphId when New session is clicked
  useEffect(() => {
    if (isNewChat) {
      if (chatBotMessages?.length > 1) {
        setGraphId("");
        dispatch(setAiGraphId(""));
        setIsNewChat(false);
        setChatBotMessages([
          {
            message: "Hello, How can I help you?",
            isUser: false,
            isNewMessage: false,
            id: "",
            isDefaultMsg: true,
            turnSubprocesses: [],
          },
        ]);
        setFilterAthleteDetails([]);
        setTempFilterAthleteDetails([]);
        setComputeData(null);
        setGraphData({});
        setActivateGraph(false);
      }
    }
  }, [isNewChat, chatBotMessages, dispatch, athleteList]);

  const handleSendClick = async (): Promise<void> => {
    if (!currentAthlete?.id || userMessage.trim() === "") return;
    if (!graphId) {
      const apiPayload = filterAthleteDetails?.map((athlete: AthleteType) => ({
        type: "AthleteDataSourceMetadata",
        athlete_id: athlete?.athlete_id.toString(),
        athlete_image_identifier: athlete?.athlete_image_identifier,
        athlete_flag_identifier: athlete?.athlete_flag_identifier,
        athlete_full_name: athlete?.athlete_full_name,
      }));
      dispatch(fetchAiGraphId(apiPayload));
      setIsNewChat(false);
    }
    setIsButtonClicked(true);
    setIsLoading(true);
    setChatBotMessages((prevMessages) => [
      ...prevMessages,
      {
        message: userMessage,
        isUser: true,
        isNewMessage: true,
        id: "24",
        isDefaultMsg: false,
        turnSubprocesses: [],
      },
      {
        message: "",
        isUser: false,
        isNewMessage: false,
        id: "24",
        isDefaultMsg: false,
        turnSubprocesses: [],
      },
    ]);
  };

  // funtion to create EChart Options
  const createGraphOptions = (
    aiGraphInScope: any,
    plots: any,
    series: any,
    yAxisLabel: any,
    xAxisLabel: any
  ) => {
    const dateUnits = ["date", "day"];
    /* 
    * Format of Mapping:
    <y_axies_length>: {
          left: <left_offset>,
          right: <right_offset>
        } 
    */
    const yAxisGridOffsetMapping: any = {
      1: {
        left: 15,
        right: 15,
      },
      2: {
        left: 15,
        right: 15,
      },
      3: {
        left: 50,
        right: 15,
      },
      4: {
        left: 50,
        right: 50,
      },
      5: {
        left: 80,
        right: 50,
      },
      6: {
        left: 100,
        right: 150,
      },
    };

    return {
      title: {
        text: aiGraphInScope?.title?.value,
        left: "left",
        textStyle: {
          color: COLOR_WHITE,
          fontSize: 12,
          fontWeight: 550,
          fontFamily: "Montserrat",
          // width: 220,
          // overflow: "truncate"
        },
      },
      textStyle: {
        fontFamily: "Montserrat, sans=serif",
        fontSize: 12,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        backgroundColor: COLOR_TOOLTIP,
        borderColor: COLOR_TOOLTIP,
        formatter: function (params: any) {
          let tooltipTopTitle = "";
          if (dateUnits?.includes(aiGraphInScope?.x_axes[0]?.unit)) {
            const date = new Date(params[0].name);
            tooltipTopTitle = date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            });
          } else tooltipTopTitle = params[0].name;

          let tooltipContent = tooltipTopTitle + "<br/>";
          params.forEach((param: any) => {
            const marker = param.marker;
            const seriesName = param.seriesName;
            const value = param.value;

            if (value && value !== null) {
              tooltipContent +=
                marker + " " + seriesName + ":  " + `<b>${value}</b>` + "<br/>";
            }
          });
          return `<div style="font-size: 11px;">${tooltipContent}</div>`;
        },
        textStyle: {
          color: COLOR_WHITE,
          fontFamily: "Montserrat, sans=serif",
          fontSize: 12,
          itemGap: 20,
        },
      },
      legend: {
        orient: "horizontal",
        itemGap: 12,
        textStyle: {
          color: COLOR_WHITE,
          fontFamily: "Montserrat, sans=serif",
          fontSize: 10,
        },
        top: "10%",
      },
      barMaxWidth: "12px",
      grid: {
        top: 80,
        left: yAxisGridOffsetMapping[
          aiGraphInScope?.computed_data?.y_axes_data?.length
        ]?.left,
        bottom: 20,
        right:
          yAxisGridOffsetMapping[
            aiGraphInScope?.computed_data?.y_axes_data?.length
          ]?.right,
        containLabel: true,
      },
      xAxis: xAxisLabel,
      yAxis: yAxisLabel,
      series: series,
      darkMode: true,
    };
  };

  const getYAxisPosition = (id: string, yAxisMetaData: any) => {
    const yAxisData = yAxisMetaData?.find((axis: any) => {
      return axis?.linked_axis_ids?.includes(id);
    });
    return yAxisData?.position;
  };

  // to create graph Option from the compute Data response
  useEffect(() => {
    if (computeData?.ai_graph_in_scope) {
      (async () => {
        const aiGeneratedGraph =
          computeData?.ai_graph_in_scope?.ai_generated_graphs[0];
        const plots = aiGeneratedGraph?.plots || [];
        const computedData = aiGeneratedGraph?.computed_data;

        const dateUnits = ["date", "day"];
        const isDateUnit = dateUnits?.includes(
          aiGeneratedGraph?.x_axes[0]?.unit
        );
        // 1. Map the X Axis data
        const xAxisLabel = {
          type: aiGeneratedGraph?.x_axes[0]?.series_data_type || "category",
          name: aiGeneratedGraph?.x_axes[0]?.unit_readable_short_name,
          nameLocation: "middle",
          nameGap: 25,
          data: aiGeneratedGraph?.computed_data?.x_axes_data[0]
            ?.series_only_values_data,
          axisLabel: {
            formatter: isDateUnit ? customDateFormatter : `{value}`,
            rotate: 0,
            textStyle: {
              fontSize: 10,
              color: COLOR_WHITE,
            },
          },
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },
          axisPointer: {
            label: {
              fontSize: 11,
              formatter: isDateUnit
                ? (axisObject: any) => {
                  const value = axisObject?.value;
                  return new Date(value).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  });
                }
                : `{value}`,
            },
          },
        };

        // 2. Map the Y Axis data
        const yAxisLabel = aiGeneratedGraph?.y_axes?.map(
          (yAxis: YAxis, index: number) => {
            const matchingPlot = plots?.find((plot: Plot) =>
              yAxis?.linked_plot_ids?.includes(plot.id)
            );

            // offset values for multiple y axes on the same position (max 6 y axes)
            const offsets = {
              1: 30,
              2: 60,
              3: 90,
              4: 120,
              5: 150,
              6: 180,
            };

            return {
              type: "value", // type is not being sent from the api, so defaulting to value
              name: yAxis?.unit_readable_short_name,
              position: getYAxisPosition(yAxis.id, computedData?.y_axes_data),
              axisLabel: {
                formatter: `{value}`,
                color: matchingPlot?.plot_view_metadata?.color_name,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: matchingPlot?.plot_view_metadata?.color_name,
                },
              },
              axisTick: {
                show: true,
              },
              axisPointer: {
                show: false
              },
              splitLine: {
                show: false,
              },
              textStyle: {
                color: COLOR_WHITE,
                fontFamily: "Montserrat, sans-serif",
                fontSize: 12,
              },
              ...(index > 1 && { offset: 30 * index }),
              min: 'dataMin',
              max: 'dataMax',
            };
          }
        );

        const checkIfLinkedYAxisExists = (currentPlotId: string) => {
          const matchingYAxes = aiGeneratedGraph?.y_axes?.find((axis: Axis) =>
            axis.linked_plot_ids.includes(currentPlotId)
          );
          if (matchingYAxes) {
            const yAxisDataElement = computedData?.y_axes_data?.find(
              (axis: YAxisData) => axis.linked_axis_ids[0] === matchingYAxes.id
            );
            if (yAxisDataElement) return true;
            else return false;
          } else return false;
        };

        const getYAxisIndex = (currentPlotId: string) => {
          let yAxisIndex = null;
          const matchingYAxes = aiGeneratedGraph?.y_axes?.find((axis: Axis) =>
            axis.linked_plot_ids.includes(currentPlotId)
          );
          if (matchingYAxes) {
            computedData?.y_axes_data?.forEach((axis: YAxisData, index: number) => {
              if (axis?.linked_axis_ids?.[0] === matchingYAxes?.id) {
                yAxisIndex = index;
              }
            });
          }
          return yAxisIndex;
        };

        // 3. Map the Series data
        const series = aiGeneratedGraph?.computed_data?.plots_data?.map(
          (plotData: PlotData, index: number) => {
            const matchingPlot = plots.find(
              (plot: Plot) => plot.id === plotData.linked_plot_id
            );

            return {
              name: filterAthleteDetails?.length > 1 ? plotData?.readable_name_with_datasource : plotData?.name,
              type: matchingPlot?.plot_view_metadata.type,
              data: convertToSparseSeriesData(plotData?.series_key_value_data),
              color: matchingPlot?.plot_view_metadata.color_name,
              showSymbol: matchingPlot?.plot_view_metadata.is_show_on_legend,
              connectNulls: true,
              backgroundStyle: {
                color: matchingPlot
                  ? "rgba(35, 41, 50, 0.6)"
                  : "rgba(0, 0, 0, 0)",
              },
              ...(aiGeneratedGraph?.y_axes?.length > 1 &&
                index !== 0 &&
                checkIfLinkedYAxisExists(plotData?.linked_plot_id) &&
                index < aiGeneratedGraph?.y_axes?.length && {
                yAxisIndex: getYAxisIndex(plotData?.linked_plot_id),
              }),
              ...(matchingPlot?.plot_view_metadata?.is_area_shaded_plot && {
                areaStyle: {
                  color:
                    matchingPlot?.plot_view_metadata?.area_shaded_color_name,
                },
              }),
            };
          }
        );

        const ConfigOption = createGraphOptions(
          aiGeneratedGraph,
          plots,
          series,
          yAxisLabel,
          xAxisLabel
        );
        setGraphData(ConfigOption);
      })();
    }
  }, [computeData, filterAthleteDetails]);

  // to call updateGraphConfig and compute Data when athlete change
  const handlePatchConfig = useCallback(
    async (data) => {
      const athleteConfigRes = await updateMultipleAthleteConfig(graphId, data);
      setIsLoading(true);
      const resp = await getAiGraphComputeData(graphId);
      setActivateGraph(true);
      setComputeData(resp);
      setIsLoading(false);
    },
    [graphId]
  );

  // to filter the selected popup
  const handleFilterPopup = useCallback(async () => {
    setIsSubmit(true);
    if (tempFilterAthleteDetails && tempFilterAthleteDetails.length > 0) {
      setFilterAthleteDetails(tempFilterAthleteDetails);
      if (graphId) {
        handlePatchConfig(tempFilterAthleteDetails);
      }
    }
    setIsSubmit(false);
    setTogglePopup(false);
  }, [handlePatchConfig, graphId, tempFilterAthleteDetails]);

  // to clear the selected athlete
  const handleClearAll = async () => {
    setFilteredAthlete([currentAthlete]);
    const athleteCountryAlpha2Code =
      athleteList?.[0]?.countryCode &&
        countryCodesMapping[athleteList?.[0].countryCode]
        ? countryCodesMapping[athleteList?.[0]?.countryCode]
        : "AE";
    const athleteFlagUrl = `${DEFAULT_CDN_URL}${athleteCountryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;
    const data = [
      {
        athlete_flag_identifier: athleteFlagUrl,
        athlete_full_name: athleteList?.[0]?.fullName,
        athlete_id: athleteList?.[0]?.id?.toString(),
        athlete_image_identifier: athleteList?.[0]?.picture,
        athlete_profile_pic: null,
        type: "AthleteDataSourceMetadata",
      },
    ];
    setFilterAthleteDetails([]);
    setTempFilterAthleteDetails([]);
    // handlePatchConfig(data)
    // handlePatchConfig(currentAthlete?.id);
    setTogglePopup(false);
  };

  const handleOnClose = () => {
    if (!isSubmit) {
      setTempFilterAthleteDetails(filterAthleteDetails);
    }
    setTogglePopup(false);
  };

  return (
    <div className={styles.container}>
      <div className={clsx(!isGraphExpanded && [styles.leftSection])}>
        {!isGraphExpanded && (
          <ChatHistory
            graphHistory={graphHistory}
            setIsNewChat={setIsNewChat}
            isNewChat={isNewChat}
            loading={historyLoading}
            setHistoryId={setHistoryId}
            historyId={historyId}
            setOnDeleteGraph={setOnDeleteGraph}
            onDeleteGraph={onDeleteGraph}
            chatTitleUpdated={chatTitleUpdated}
            setTitleUpdateCompleted={setChatTitleUpdated}
          />
        )}
      </div>
      <div
        className={clsx(
          !isGraphExpanded ? [styles.graphWrapper] : [styles?.graphExpand]
        )}
      >
        <div
          className={
            !isGraphExpanded ? styles.topSection : styles.topSectionExpanded
          }
        >
          {isGraphExpanded ? (
            <ResizableBox
              width={window.innerWidth * 0.6}
              height={window.innerHeight}
              minConstraints={[500, 500]}
              maxConstraints={[120000, 1800]}
              axis="x"
              handle={<div className={styles.customHandle} />}
              resizeHandles={["e"]} // Allow resizing from all sides
            >
              <div
                className={styles.profile}
                onClick={() => setTogglePopup(true)}
              >
                {filterAthleteDetails?.length <= 0 ? (
                  <div className={styles.profile}>
                    <span className={styles.name}>Select Athletes</span>
                    <DropDown className={styles.dropdown} />
                  </div>
                ) : (
                  <>
                    <img
                      src={filterAthleteDetails?.[0]?.athlete_image_identifier}
                      alt="Profile"
                      className={styles.athleteImage}
                    />
                    <span className={styles.name}>
                      {filterAthleteDetails?.[0]?.athlete_full_name}{" "}
                    </span>

                    <img
                      src={filterAthleteDetails?.[0]?.athlete_flag_identifier}
                      alt={filterAthleteDetails?.[0]?.athlete_flag_identifier}
                      className={styles.athleteFlag}
                    />
                    <DropDown className={styles.dropdown} />
                    <div className={styles?.athleteCounterText}>
                      <span className={styles.athleteCounter}>
                        {" "}
                        {filterAthleteDetails?.length > 1 &&
                          `+${filterAthleteDetails?.length - 1}`}
                      </span>
                      {filterAthleteDetails?.length > 1 && (
                        <p className={styles.athletesText}>Athletes</p>
                      )}
                    </div>
                  </>
                )}
              </div>

              <GraphChart
                className={clsx(
                  isGraphExpanded
                    ? [styles.graphFullHeight, styles.leftSectionExpanded]
                    : styles?.graphContainer
                )}
                onExpand={handleExpandClick}
                isExpanded={isGraphExpanded}
                graphData={graphData}
                filteredAthlete={filteredAthlete}
                setFilteredAthlete={setFilteredAthlete}
                activateGraph={activateGraph}
                isLoading={isLoading}
              />
            </ResizableBox>
          ) : (
            <>
              {!isGraphExpanded && (
                <div
                  className={styles.userInfo}
                  onClick={() => setTogglePopup(true)}
                >
                  {filterAthleteDetails?.length <= 0 ? (
                    <div className={styles.profile}>
                      <span className={styles.name}>Select Athletes</span>
                      <DropDown className={styles.dropdown} />
                    </div>
                  ) : (
                    <>
                      <div className={styles.profile}>
                        <img
                          src={
                            filterAthleteDetails?.[0]?.athlete_image_identifier
                          }
                          alt="Profile"
                          className={styles.athleteImage}
                        />
                        <span className={styles.name}>
                          {filterAthleteDetails?.[0]?.athlete_full_name}{" "}
                        </span>
                      </div>
                      <div className={styles.profileInfo}>
                        <img
                          src={
                            filterAthleteDetails?.[0]?.athlete_flag_identifier
                          }
                          alt={
                            filterAthleteDetails?.[0]?.athlete_flag_identifier
                          }
                          className={styles.athleteFlag}
                        />
                        <DropDown className={styles.dropdown} />
                      </div>
                      <div className={styles?.athleteCounterText}>
                        <span className={styles.athleteCounter}>
                          {" "}
                          {filterAthleteDetails?.length > 1 &&
                            `+${filterAthleteDetails?.length - 1}`}
                        </span>
                        {filterAthleteDetails?.length > 1 && (
                          <p className={styles.athletesText}>Athletes</p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
              <GraphChart
                className={clsx(
                  isGraphExpanded
                    ? [styles.graphFullHeight, styles.leftSectionExpanded]
                    : styles?.graphContainer
                )}
                onExpand={handleExpandClick}
                isExpanded={isGraphExpanded}
                graphData={graphData}
                filteredAthlete={filteredAthlete}
                setFilteredAthlete={setFilteredAthlete}
                activateGraph={activateGraph}
                isLoading={isLoading}
              />
              {(isLoading) && <HorizontalLoader />}
            </>
          )}
        </div>

        <div className={styles.chatWrapper}>
          <ChatAiBody
            isExpanded={isGraphExpanded}
            isLoading={isLoading}
            onSendClick={handleSendClick}
            onChangeMultiline={handleInputChangeMultiline}
            messages={chatBotMessages}
            turnProcess={turnProcess}
            latestMsgId={latestMsgId}
          />

          <GraphAiChatInput
            isExpanded={isGraphExpanded}
            isLoading={isLoading}
            name="chatInput"
            onSend={handleSendClick}
            onChangeMultiline={handleInputChangeMultiline}
            disable={filterAthleteDetails?.length <= 0 ? true : false}
          />
        </div>
      </div>
      {/* </div> */}
      {togglePopup && (
        <AthletePopup
          handleClearAll={handleClearAll}
          handleSubmit={handleFilterPopup}
          athleteList={athleteList}
          onClose={handleOnClose}
          filterAthleteDetails={filterAthleteDetails}
          setFilterAthleteDetails={setFilterAthleteDetails}
          setTempFilterAthleteDetails={setTempFilterAthleteDetails}
          tempFilterAthleteDetails={tempFilterAthleteDetails}
          multiSelect={true}
        />
      )}
    </div>
  );
};

export default GraphAiBody;
