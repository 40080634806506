import styles from "./chat-history.module.scss";
import { ReactComponent as HorizontalDots } from "images/horizontal-dots.svg";
import clsx from "clsx";
import { ReactComponent as EditPencilBoxIcon } from "images/EditPencilBoxIcon.svg";
import { ReactComponent as DeleteIcon } from "images/trash.svg";
import { ReactComponent as ShareIcon } from "images/upload.svg";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { deleteAiGraphChatHistory } from "api/ai-graph";
import StreamingText from "./components/StreamingText";

type GraphHistoryItem = {
  id: string;
  ai_graph_meta_data?: {
    title?: {
      value: string;
      is_ai_generated?: boolean;
    };
  };
};

type Props = {
  graphHistory: GraphHistoryItem[];
  setIsNewChat: (value: boolean) => void;
  isNewChat: boolean;
  loading: boolean;
  setHistoryId: (value: string) => void;
  historyId: string;
  setOnDeleteGraph: (value: boolean) => void;
  onDeleteGraph: boolean;
  chatTitleUpdated: boolean;
  setTitleUpdateCompleted: (value: boolean) => void;
};

const ChatHistory = ({
  graphHistory,
  setIsNewChat,
  isNewChat,
  loading,
  setHistoryId,
  historyId,
  setOnDeleteGraph,
  onDeleteGraph,
  chatTitleUpdated,
  setTitleUpdateCompleted,
}: Props) => {
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [menuPosition, setMenuPosition] =
    useState<{ top: number; left: number } | null>(null);
  const aiGraphId = useSelector((state: any) => state?.aiGraph?.aiGraphId);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    setIsNewChat(true);
    setHistoryId("");
  };

  // Change event parameter type to accommodate both SVG and HTML div
  const toggleMenu = (
    id: string,
    event: React.MouseEvent<SVGSVGElement | HTMLDivElement>
  ) => {
    const { clientY, clientX } = event;

    setOpenMenuId(openMenuId === id ? null : id);
    if (openMenuId === id) {
      setMenuPosition(null);
    } else {
      setMenuPosition({ top: clientY, left: clientX });
    }
  };

  const onDelete = async (id: string) => {
    const res = await deleteAiGraphChatHistory(id);
    setOnDeleteGraph(!onDeleteGraph);
    const currentIndex = graphHistory.findIndex((h: any) => h.id === id);
    const nextId =
      graphHistory[currentIndex + 1]?.id || graphHistory[currentIndex - 1]?.id;
    if (nextId) {
      setHistoryId(nextId);
    }else{
      setHistoryId("")
    }
  };

  const handleStreamingCompleted = (value: boolean) => {
    setTitleUpdateCompleted(!value);
  }

  // Hook to detect outside clicks and close the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenMenuId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuId]);

  const checkIfAIGenerated = () => {
    const graphHistoryItem = graphHistory.find((history: any) => history.id === aiGraphId);
    return graphHistoryItem?.ai_graph_meta_data?.title?.is_ai_generated;
  }

  return (
    <>
      <div className={styles.history}>
        <div className={styles.chatHistoryWrapper}>
          <ul
            className={clsx(
              styles.chatHistoryContainer,
              styles.folderTextBorder
            )}
          >
            {loading && (
              <div className={styles.chatHistory}>
                <li className={styles.chatHistoryText}>New chat</li>
              </div>
            )}
            {graphHistory?.map((history: any, index) => {
              return (
                <div
                  key={history.id}
                  className={clsx(
                    styles.chatHistory,
                    history?.id === aiGraphId && styles?.chatHistoryHover
                  )}
                  onClick={() => setHistoryId(history?.id)}
                >
                  <span className={styles.chatHistoryText}>
                    {(history?.id === aiGraphId && chatTitleUpdated && checkIfAIGenerated()) ? <StreamingText text={history?.ai_graph_meta_data?.title?.value} setStreamingCompleted={handleStreamingCompleted} /> : history?.ai_graph_meta_data?.title?.value}
                  </span>

                  {openMenuId === history?.id && menuPosition && (
                    <div
                      className={
                        styles.menu +
                        (openMenuId === history?.id ? " " + styles.visible : "")
                      }
                      style={{
                        top: menuPosition.top + 14,
                        left: menuPosition.left - 20,
                      }} // Adjust to position correctly
                      ref={menuRef}
                    >
                      <div className={styles.menuItem}>
                        <ShareIcon className={styles.menuOption} />
                        <span>Share</span>
                      </div>
                      <div
                        className={clsx([styles.menuItem, styles.deleteItem])}
                        onClick={() => onDelete(history?.id)}
                      >
                        <DeleteIcon className={clsx([styles.menuOption, styles.deleteIcon])} />
                        <span>Delete</span>
                      </div>
                    </div>
                  )}
                  <div className={styles.deleteIconContainer} onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(history?.id, e);
                  }} >
                    <HorizontalDots
                      className={styles.deleteIcon}
                    />
                  </div>
                </div>
              );
            })}
          </ul>
        </div>
        <div className={styles.newSessionBtnWrapper}>
          <button type="button" className={styles.button} onClick={handleClick}>
            New Session <EditPencilBoxIcon className={styles.expandDown} />
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatHistory;